import React, { type FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRouter from './AppRouter';
import AuthProvider from './providers/AuthProvider';

const App: FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppRouter />
      </Router>
    </AuthProvider>
  );
};

export default App;
