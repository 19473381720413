import React, { type FC } from 'react';

import t from '../../lib/translate';
import LoadingButton from '../../components/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ROLE_DI } from '../../api/util';
import { orderCompleteModel } from '../../redux/actions/transport';

const TransportAdmin: FC = () => {
  const roles = useAppSelector((state) => state.userInfo.user?.grantedRoles);
  const dispatch = useAppDispatch();

  const handleSubmit = (): void => {
    void dispatch(orderCompleteModel());
  };

  if (!roles?.includes(ROLE_DI)) {
    return null;
  }

  return (
    <div className={'transport-admin'}>
      <span>{t('transport.di.admin')}</span>
      <p>{t('transport.di.admin.description')}</p>
      <LoadingButton
        label={t('transport.order.complete.model')}
        isLoading={false}
        onClick={handleSubmit}
        disabled={false}
      />
    </div>
  );
};

export default TransportAdmin;
