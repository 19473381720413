import auth from '../../lib/auth';

const authenticationReducer = () => {
  return auth.isAuthenticated;
};

export const logout = () => () => {
  auth.logout();
  window.location.reload();
};

export default authenticationReducer;
