import React, { type FC, useState } from 'react';

import Form from '../../components/Form';
import InputText from '../../components/InputText';
import InputRadioButton from '../../components/InputRadioButton';
import InputCheckbox from '../../components/InputCheckbox';
import InputSelectKeyValue from '../../components/InputSelectKeyValue';
import { resetErrors } from '../../redux/actions/resetErrors';
import { addStationIntegration } from '../../redux/actions/stationIntegrations';
import { type RootState } from 'src/redux/store';

import t from '../../lib/translate';
import IntegrationParameter from '../../components/IntegrationParameter';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { type Integration } from 'src/types';
import DeprecationWarning from '../../components/DeprecationWarning';
import { GEOADJUST_PARAM } from '../../components/IntegrationParamList';

export enum FormType {
  CLOSED,
  OUTGOING,
  INCOMING,
  LOGIC,
}

interface Props {
  stationId: number;
  type: FormType;
  finished: () => void;
  formTitle: string;
}

interface PropertyInput {
  key: string;
  value: string;
}
interface IntegrationParamInput {
  integrationParameterTypeId: string | number;
  value: string | number;
}

interface FormInput {
  integrationId: number;
  priority: number;
  propertyFilters: PropertyInput[];
  propertyExceptions: PropertyInput[];
  params: Map<number | string, number | string>;
}

const INITIAL_STATE: FormInput = {
  integrationId: 0,
  priority: 0,
  propertyFilters: [],
  propertyExceptions: [],
  params: new Map(),
};

interface Filterable {
  outgoingCompatible: boolean;
  returnCompatible: boolean;
  returnLogicCompatible: boolean;
}

const isValidForType = (data: Filterable, type: FormType): boolean => {
  switch (type) {
    case FormType.OUTGOING:
      return data.outgoingCompatible;
    case FormType.INCOMING:
      return data.returnCompatible;
    case FormType.LOGIC:
      return data.returnLogicCompatible;
    default:
      return true;
  }
};

const IntegrationForm: FC<Props> = ({ type, stationId, finished, formTitle }) => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state: RootState) => state.isLoading.primary);
  const error = useAppSelector((state: RootState) => state.error.station.modal);
  const integrations = useAppSelector((state: RootState) => state.staticData.integrations);
  const properties = useAppSelector((state: RootState) => state.staticData.properties);
  const propertyValues = useAppSelector((state: RootState) => state.station.propertyValues);
  const stationIntegrations = useAppSelector((state: RootState) => state.station.integrations);

  const mappedProperties = properties
    .filter((prop) => isValidForType(prop, type))
    .map((prop) => ({
      value: prop.propertyId,
      label: t(prop.i18nKey),
      outgoingCompatible: prop.outgoingCompatible,
      returnCompatible: prop.returnCompatible,
      returnLogicCompatible: prop.returnLogicCompatible,
    }));

  const mappedPropertyValues = {};
  Object.keys(propertyValues).forEach((key) => {
    mappedPropertyValues[key] = propertyValues[key].map((pv) => ({
      value: pv.externalId,
      label: t(pv.label),
    }));
  });

  const mappedIntegrations = integrations
    .filter((i) => isValidForType(i, type))
    .map((i) => ({
      value: i.integrationId,
      label: t(`integration.${i.name}`),
    }));

  const [values, setValues] = useState<FormInput>(INITIAL_STATE);
  const [matchAll, toggleMatchAll] = useState(true);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration | undefined>(
    undefined
  );

  const handleSelectIntegration = (name, integrationId): void => {
    const integration = integrations.find((i) => i.integrationId === integrationId);
    setSelectedIntegration(integration);

    let priority = 0;
    if (integration?.returnLogicCompatible) {
      priority =
        1 + (stationIntegrations ? Math.max(...stationIntegrations.map((si) => si.priority)) : 0);
    }

    setValues({ ...values, integrationId, priority, params: new Map() });
  };

  const handleChangePriority = (priority): void => {
    setValues({ ...values, priority });
  };

  const handleChangePropertyFilter = (name, propertyFilters): void => {
    setValues({ ...values, propertyFilters });
  };

  const handleChangePropertyExceptions = (name, propertyExceptions): void => {
    setValues({ ...values, propertyExceptions });
  };

  const handleChangeParam = (paramId: number, paramValue: string | number): void => {
    const params = values.params;
    params.set(paramId, paramValue);
    setValues({ ...values, params });
  };

  const handleChangeMatchAll = (matchAll: boolean): void => {
    if (matchAll) {
      // Remove any filters if all parcels should be matched
      setValues({ ...values, propertyFilters: [] });
    }
    toggleMatchAll(matchAll);
  };

  const enableSubmit = (): boolean => {
    const integrationSelected = values.integrationId > 0;
    const filterSelected = matchAll || values.propertyFilters.length > 0;

    const noEmptyFilters =
      values.propertyFilters.filter((filter) => !(filter.key && filter.value)).length === 0;
    const noEmptyExceptions =
      values.propertyExceptions.filter((exception) => !(exception.key && exception.value))
        .length === 0;

    const validPriority = selectedIntegration?.returnLogicCompatible
      ? values.priority > 0
      : values.priority === 0;

    let validMonths = true;
    if (values.integrationId === 9) {
      const monthsValue = values.params.get(6)?.toString() ?? '';
      validMonths = /^([1-9][0-9]*)$/.test(monthsValue);
    }

    return (
      integrationSelected &&
      filterSelected &&
      noEmptyFilters &&
      noEmptyExceptions &&
      validPriority &&
      validMonths
    );
  };

  const closeModalAndResetFormErrors = (): void => {
    void dispatch(resetErrors());
    finished();
  };

  const handleSubmit = (): void => {
    if (enableSubmit()) {
      const propertyFilters = values.propertyFilters.map((pf) => ({
        propertyId: pf.key,
        externalId: pf.value,
      }));
      const propertyExceptions = values.propertyExceptions.map((pe) => ({
        propertyId: pe.key,
        externalId: pe.value,
      }));
      const integrationParameterValues: IntegrationParamInput[] = [];

      values.params.forEach((value, key) => {
        integrationParameterValues.push({
          integrationParameterTypeId: key,
          value,
        });
      });

      void dispatch(
        addStationIntegration(
          stationId,
          values.integrationId,
          {
            priority: values.priority,
            propertyFilters,
            propertyExceptions,
            integrationParameterValues,
          },
          closeModalAndResetFormErrors
        )
      );
    }
  };

  return (
    <Form
      label={formTitle}
      isLoading={isLoading}
      error={error}
      enableSubmit={enableSubmit()}
      onClose={closeModalAndResetFormErrors}
      onSubmit={handleSubmit}>
      <InputRadioButton
        name='integrationId'
        label='integration.label.integration.id'
        value={values.integrationId}
        options={mappedIntegrations}
        handleChange={handleSelectIntegration}
      />
      {selectedIntegration?.parameters?.map((parameter) => (
        <>
          {parameter.integrationParamTypeId === GEOADJUST_PARAM && (
            <DeprecationWarning message={'integration.form.geoadjust.deprecation.warning'} />
          )}
          <IntegrationParameter
            key={parameter.integrationParamTypeId}
            parameter={parameter}
            onChange={handleChangeParam}
            value={values.params.get(parameter.integrationParamTypeId)}
          />
        </>
      ))}
      {selectedIntegration?.returnLogicCompatible && (
        <InputText
          name='priority'
          type='number'
          value={values.priority}
          label={t('integration.label.priority')}
          info={t('integration.label.priority.info')}
          handleChange={(e) => {
            handleChangePriority(e.target.value);
          }}
        />
      )}
      <InputCheckbox
        key='matchAll'
        label='integration.label.matchAll.title'
        info='integration.label.matchAll.info'
        options={[
          {
            name: 'matchAll',
            value: matchAll,
            label: 'integration.label.matchAll',
          },
        ]}
        handleChange={() => {
          handleChangeMatchAll(!matchAll);
        }}
        toggle
      />
      {!matchAll && (
        <InputSelectKeyValue
          name='propertyFilters'
          label='integration.label.property.filters'
          info='integration.label.property.filters.info'
          placeholderKey='config.label.propertyFilters.property'
          placeholderValue='config.label.propertyFilters.value'
          selectedKeyValues={values.propertyFilters}
          keyOptions={mappedProperties}
          keyValueOptions={mappedPropertyValues}
          onChange={handleChangePropertyFilter}
          required
        />
      )}
      <InputSelectKeyValue
        name='propertyExceptions'
        label='integration.label.property.exception'
        info='integration.label.property.exception.info'
        placeholderKey='config.label.propertyExceptions.property'
        placeholderValue='config.label.propertyExceptions.value'
        selectedKeyValues={values.propertyExceptions}
        keyOptions={mappedProperties}
        keyValueOptions={mappedPropertyValues}
        onChange={handleChangePropertyExceptions}
      />
    </Form>
  );
};

export default IntegrationForm;
