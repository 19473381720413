import React, { type FC, useRef, useState } from 'react';

import InputCheckbox from '../../components/InputCheckbox';
import InputDatePicker from '../../components/InputDatePicker';
import InputText from '../../components/InputText';
import InputTextArea from '../../components/InputTextArea';
import { type SystemMessage, type SystemMessageRequest } from '../../types';
import LoadingButton from '../../components/LoadingButton';
import t from '../../lib/translate';
import { useAppDispatch } from '../../redux/hooks';
import { createOrUpdateSystemInfo } from '../../redux/actions/systemInfo';
import { changeTimeZone, mergeDateAndTime, parseTimeFromDateString } from '../../lib/dateUtil';

const clockPattern = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';

interface Props {
  systemMessage?: SystemMessage;
  closeModal: () => void;
}

const SystemMessageForm: FC<Props> = ({ systemMessage, closeModal }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [message, setMessage] = useState<string>(
    systemMessage != null ? systemMessage.message : ''
  );
  const [fromDate, setFromDate] = useState<Date>(
    systemMessage != null ? changeTimeZone(new Date(systemMessage.fromDate)) : new Date()
  );
  const [toDate, setToDate] = useState<Date>(
    systemMessage != null ? changeTimeZone(new Date(systemMessage.toDate)) : tomorrow
  );
  const [fromTime, setFromTime] = useState<string>(
    systemMessage != null ? parseTimeFromDateString(systemMessage.fromDate) : '00:00'
  );
  const [toTime, setToTime] = useState<string>(
    systemMessage != null ? parseTimeFromDateString(systemMessage.toDate) : '00:00'
  );
  const [isImportant, setIsImportant] = useState<boolean>(
    systemMessage != null ? !!systemMessage.importantFromDate : false
  );

  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: Event): void => {
    e.preventDefault();
    formRef.current?.reportValidity();

    if (!formRef.current?.checkValidity()) {
      return;
    }

    const request: SystemMessageRequest = {
      systemInfoId: systemMessage != null ? systemMessage.systemInfoId : null,
      message,
      fromDate: mergeDateAndTime(fromDate, fromTime),
      toDate: mergeDateAndTime(toDate, toTime),
      importantFromDate: isImportant ? mergeDateAndTime(fromDate, fromTime) : null,
    };

    void dispatch(createOrUpdateSystemInfo(request));
    closeModal();
  };

  return (
    <form className='form' ref={formRef}>
      <div className='header label'>
        {systemMessage != null
          ? t('form.system.message.edit.header')
          : t('form.system.message.create.header')}
      </div>
      <InputTextArea
        name='message'
        value={message}
        label='form.system.message.message'
        onChange={(event) => {
          setMessage(event.currentTarget.value);
        }}
      />
      <InputCheckbox
        options={[
          {
            name: 'isImportant',
            value: isImportant,
            label: 'form.system.message.isImportant',
          },
        ]}
        handleChange={() => {
          setIsImportant(!isImportant);
        }}
      />
      <div className='input-datepicker-group'>
        <InputDatePicker
          name='fromDate'
          label='form.system.message.fromDate'
          value={fromDate}
          beforeLimit={new Date()}
          onChange={(_, value) => {
            setFromDate(value);
          }}
        />
        <InputText
          name='fromTime'
          label='form.system.message.fromTime'
          value={fromTime}
          handleChange={(event) => {
            setFromTime(event.currentTarget.value);
          }}
          maxLength={5}
          pattern={clockPattern}
        />
      </div>
      <div className='input-datepicker-group'>
        <InputDatePicker
          name='toDate'
          label='form.system.message.toDate'
          value={toDate}
          beforeLimit={new Date()}
          onChange={(_, value) => {
            setToDate(value);
          }}
        />
        <InputText
          name='toTime'
          label='form.system.message.toTime'
          value={toTime}
          handleChange={(event) => {
            setToTime(event.currentTarget.value);
          }}
          maxLength={5}
          pattern={clockPattern}
        />
      </div>
      <div className='spread'>
        <button type='button' className='cancel' onClick={closeModal}>
          {t('button.cancel')}
        </button>
        <LoadingButton
          label={t('button.submit')}
          onClick={handleSubmit}
          isLoading={false}
          disabled={!message}
        />
      </div>
    </form>
  );
};

export default SystemMessageForm;
